import React, { useState, useEffect } from "react";
import AWS from "aws-sdk";
import { User } from "pages/Provision/Provision.type";
import "pages/Provision/Provision.css";

type VerifyUserFormProps = {
  selectedUser: User;
  userPoolId: string;
  onClose: () => void;
};

const VerifyUserForm: React.FC<VerifyUserFormProps> = ({
  selectedUser,
  userPoolId,
  onClose,
}) => {
  const [statusMessage, setStatusMessage] = useState("");
  const [isVerified, setIsVerified] = useState<boolean | null>(null);

  useEffect(() => {
    const checkUserStatus = async () => {
      const cognito = new AWS.CognitoIdentityServiceProvider({
        region: import.meta.env.VITE_AWS_REGION,
      });

      const params = {
        UserPoolId: userPoolId,
        Username: selectedUser.emailAddress,
      };

      try {
        const data = await cognito.adminGetUser(params).promise();
        const emailVerified = data.UserAttributes?.find(
          (attr) => attr.Name === "email_verified",
        )?.Value;

        setIsVerified(emailVerified === "true");
      } catch (error) {
        console.error("Error getting user status:", error);
        setIsVerified(null);
      }
    };

    if (userPoolId) {
      checkUserStatus();
    }
  }, [userPoolId, selectedUser.emailAddress]);

  const handleAdminConfirm = async () => {
    const cognito = new AWS.CognitoIdentityServiceProvider({
      region: import.meta.env.VITE_AWS_REGION,
    });

    const updateParams = {
      UserPoolId: userPoolId,
      Username: selectedUser.emailAddress,
      UserAttributes: [
        {
          Name: "email_verified",
          Value: "true",
        },
      ],
    };

    const confirmParams = {
      UserPoolId: userPoolId,
      Username: selectedUser.emailAddress,
    };

    console.log("AdminConfirm params:", confirmParams);
    try {
      await cognito.adminUpdateUserAttributes(updateParams).promise();
      await cognito.adminConfirmSignUp(confirmParams).promise();
      setStatusMessage(
        `User ${selectedUser.emailAddress} has been verified and confirmed.`,
      );
    } catch (error) {
      console.error("Error confirming user:", error);
      setStatusMessage("Failed to verify and confirm user");
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleAdminConfirm();
      }}
    >
      <h2>
        User Status for {selectedUser.firstName} {selectedUser.lastName}
      </h2>
      {statusMessage && <label className="post">{statusMessage}</label>}
      <label htmlFor="email">
        {selectedUser.emailAddress} is
        {isVerified !== null && (isVerified ? " VERIFIED" : " NOT VERIFIED")}
      </label>
      {isVerified === false && (
        <input type="submit" value="Verify/Confirm User" />
      )}
      <button type="button" onClick={onClose}>
        Close
      </button>
    </form>
  );
};

export default VerifyUserForm;
