import { useState, useContext } from "react";
import { Policy } from "pages/Provision/Provision.type";
import "pages/Provision/Provision.css";
import { signRequest } from "utils/utils";
import { FetchSiteByOperationsContext } from "pages/Provision/Provision";

interface FormErrors {
  operationDayStartTime?: string;
  operationDayEndTime?: string;
  localTimezoneOffset?: string;
}

const PolicyForm: React.FC<{ account: string; site: string }> = ({
  account,
  site,
}) => {
  const startOfYear = new Date(new Date().getFullYear(), 0, 1).getTime();
  const endOfYear = new Date(
    new Date().getFullYear(),
    11,
    31,
    23,
    59,
    59,
    999,
  ).getTime();

  const [policy, setPolicy] = useState<Policy>({
    customerResName: "",
    accountResName: account,
    siteResName: site,
    policyResName: "",
    policyType: "data-tracking",
    monitoredThreshold: {
      threshold: 0,
      operator: "=",
      unit: "percent",
    },
    monitoringFrequency: {
      unitOfTime: "mins",
      timePeriod: 10,
    },
    transmissionFrequency: {
      unitOfTime: "mins",
      timePeriod: 10,
    },
    policyName: "DEFAULT",
    policyStatus: "active",
    dataType: "mr",
    operationalPeriod: {
      periodStartDate: { epochTime: startOfYear },
      periodEndDate: { epochTime: endOfYear },
      operatingDaysOfWeek: ["Mon", "Tue", "Wed", "Thu", "Fri"],
      operatingDayStartTime: "09:00",
      operatingDayEndTime: "17:00",
      localTimezoneOffset: -7,
    },
    logLevel: "ERROR",
    creationTime: { epochTime: 0 },
    lastUpdateTime: { epochTime: 0 },
  });

  const [error, setError] = useState<string>("");
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [loading, setLoading] = useState<boolean>(false);
  const fetchSites = useContext(FetchSiteByOperationsContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    const { name, value } = event.target;
    setPolicy({
      ...policy,
      operationalPeriod: {
        ...policy.operationalPeriod,
        [name]: value,
      },
    });
  };

  const postPolicy = async () => {
    setError("");
    setLoading(true);

    const url = `/accounts/${account}/sites/${site}/policies`;
    const method = "POST";
    const host = "SITEHOURS";

    const body = {
      policyType: policy.policyType,
      monitoredThreshold: policy.monitoredThreshold,
      monitoringFrequency: policy.monitoringFrequency,
      transmissionFrequency: policy.transmissionFrequency,
      policyName: policy.policyName,
      status: policy.policyStatus,
      dataType: policy.dataType,
      operationalPeriod: {
        ...policy.operationalPeriod,
        localTimezoneOffset: parseInt(
          policy.operationalPeriod.localTimezoneOffset.toString(),
          10,
        ),
      },
      logLevel: policy.logLevel,
    };

    try {
      const signedRequest = await signRequest(host, url, method, body);
      const response = await fetch(signedRequest.url, signedRequest);

      if (!response.ok) {
        setError("Failed to create policy");
        console.error("Failed to create policy", response);
      } else {
        const createdPolicy = await response.json();
        console.log("Created policy", createdPolicy);

        createdPolicy.errorMessage
          ? setError("Failed to create policy")
          : fetchSites(account, site, ["policies"]);
        setLoading(false);
      }
    } catch (error) {
      setError("Failed to create policy");
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const validateForm = () => {
    const errors: FormErrors = {};

    if (!policy.operationalPeriod.operatingDayStartTime) {
      errors.operationDayStartTime = "Start time is required";
    }

    if (!policy.operationalPeriod.operatingDayEndTime) {
      errors.operationDayEndTime = "End time is required";
    }

    if (!policy.operationalPeriod.localTimezoneOffset) {
      errors.localTimezoneOffset = "Timezone is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    await postPolicy();
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Add Policy</h2>
      {loading && (
        <div className="loader-container">
          <div className="loader" />
        </div>
      )}
      {loading && <label className="post">Adding Policy...</label>}
      {error && <label className="error">{error}</label>}
      <label>
        Operating Days of Week: (Mon,Tue,Wed,Thu,Fri,Sat,Sun)
        <input
          type="text"
          name="operatingDaysOfWeek"
          value={policy.operationalPeriod.operatingDaysOfWeek.join(",")}
          readOnly
        />
      </label>
      <label>
        Operating Day Start Time: (HH:mm)
        <input
          type="text"
          name="operatingDayStartTime"
          value={policy.operationalPeriod.operatingDayStartTime}
          onChange={handleChange}
        />
      </label>
      {formErrors.operationDayStartTime && (
        <label className="form-error">{formErrors.operationDayStartTime}</label>
      )}
      <label>
        Operating Day End Time: (HH:mm)
        <input
          type="text"
          name="operatingDayEndTime"
          value={policy.operationalPeriod.operatingDayEndTime}
          onChange={handleChange}
        />
      </label>
      {formErrors.operationDayEndTime && (
        <label className="form-error">{formErrors.operationDayEndTime}</label>
      )}
      <label>
        Local Timezone Offset: (-7 for UTC-7 and 7 for UTC+7)
        <input
          type="number"
          name="localTimezoneOffset"
          value={policy.operationalPeriod.localTimezoneOffset}
          onChange={handleChange}
        />
      </label>
      <input type="submit" value="Add Policy" />
    </form>
  );
};

export default PolicyForm;
