import React, { useState, useMemo, useContext } from "react";
import { Link } from "react-router-dom";
import { Column, useTable, useSortBy } from "react-table";
import { Tenant, Account, Site, Floor } from "pages/Provision/Provision.type";
import { FetchSiteByOperationsContext } from "pages/Provision/Provision";
import Table from "pages/Provision/tables/Table";

import { FiEdit2 } from "react-icons/fi";

import Modal from "react-modal";
import AddFloorForm from "pages/Provision/forms/FloorForm";
import EditFloorForm from "pages/Provision/forms/EditFloorForm";
import "pages/Provision/Provision.css";

type SiteTableProps = {
  tenant: Tenant;
  account: Account;
  site: Site;
  floors: Floor[];
};

type columnData = {
  floorResName: string;
  floorName: string;
  floorIndex: number;
  ceilingHeight: number;
  readerPlacementLevel: number;
  createTime: string;
  lastUpdateTime: string;
};

const FloorTable: React.FC<SiteTableProps> = ({
  tenant,
  account,
  site,
  floors,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [selectedFloor, setSelectedFloor] = useState<Floor>(null!);
  const [searchQuery, setSearchQuery] = useState("");
  const fetchSites = useContext(FetchSiteByOperationsContext);

  const data = useMemo(
    () =>
      floors
        .filter((floor) => floor.siteResName === site.siteResName)
        .map((floor) => ({
          ...floor,
          createTime: new Date(floor.creationTime.epochTime).toLocaleString(),
          lastUpdateTime: new Date(
            floor.lastUpdateTime.epochTime,
          ).toLocaleString(),
        }))
        .filter((floor) => {
          return (
            floor.floorResName
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            floor.floorName.toLowerCase().includes(searchQuery.toLowerCase())
          );
        }),
    [tenant, account, site, floors, searchQuery],
  );

  const columns: Column<columnData>[] = React.useMemo(
    () => [
      { Header: "FloorResName", accessor: "floorResName" },
      { Header: "FloorName", accessor: "floorName" },
      { Header: "FloorIndex", accessor: "floorIndex" },
      { Header: "Min Z (cm)", accessor: "readerPlacementLevel" },
      { Header: "Max Z (cm)", accessor: "ceilingHeight" },
      { Header: "CreationTime", accessor: "createTime" },
      { Header: "LastUpdateTime", accessor: "lastUpdateTime" },
      {
        Header: "Action",
        Cell: ({ row }: { row: any }) => (
          <div>
            <button
              onClick={() => {
                setSelectedFloor(
                  floors.filter(
                    (floor) => floor.floorResName === row.original.floorResName,
                  )[0],
                );
                setEditModalIsOpen(true);
              }}
            >
              <FiEdit2 />
            </button>
          </div>
        ),
      },
    ],
    [tenant, account, site, floors],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <div>
      <div className="header-container">
        <h1>Floors</h1>
        <button onClick={() => setModalIsOpen(true)}>+ Floor</button>
      </div>

      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/scrappy/provision">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/scrappy/provision/${tenant.customerResName}`}>
                {tenant.customerName}
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link
                to={`/scrappy/provision/${tenant.customerResName}/${account.accountResName}`}
              >
                {account.accountName}
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {site.siteName}
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Floors
            </li>
          </ol>
        </nav>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="modal-content"
        ariaHideApp={false}
      >
        <AddFloorForm
          account={account.accountResName}
          site={site.siteResName}
        />
      </Modal>

      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={() => setEditModalIsOpen(false)}
        className="modal-content"
        ariaHideApp={false}
      >
        <EditFloorForm selectedFloor={selectedFloor} />
      </Modal>

      <div className="table-header-container">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button onClick={() => setSearchQuery("")}>Clear</button>
        <button
          className="button-end"
          onClick={() =>
            fetchSites(account.accountResName, site.siteResName, ["floors"])
          }
        >
          Refresh
        </button>
      </div>
      <Table
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      />
    </div>
  );
};

export default FloorTable;
